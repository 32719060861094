import type { Ref } from 'vue';
import { watch, onUnmounted, ref } from 'vue';

import { isClickOnElement } from 'web/src/utils/html';

interface UseCloseOnClickProps {
  isOpen: Ref<boolean>;
  emitClose(): void;
}

interface UseCloseOnClickComposable {
  menu: Ref<Optional<HTMLElement>>;
}

export function useCloseOnClick(
  props: UseCloseOnClickProps,
): UseCloseOnClickComposable {
  const { isOpen, emitClose } = props;

  const menu = ref<Optional<HTMLElement>>();

  function closeMenu(e: MouseEvent): void {
    if (isClickOnElement(e, menu.value)) { return; }
    removeListener();
    emitClose();
  }

  function removeListener(): void {
    document.removeEventListener('click', closeMenu);
  }

  function addListener(): void {
    window.setTimeout(() => {
      document.addEventListener('click', closeMenu);
    }, 0);
  }

  watch(isOpen, (value) => {
    if (!value) { removeListener(); return; }
    addListener();
  }, { immediate: true });

  onUnmounted(removeListener);

  return { menu };
}
