<script lang="ts" setup>
import { toRef } from 'vue';

import { useCloseOnClick } from './composables';

interface SportlineDropdownMenuFullscreenProps {
  isOpen?: boolean;
}

interface SportlineDropdownMenuFullscreenEmits {
  (e: 'update:isOpen', newValue: boolean): void;
}

interface SportlineDropdownMenuFullscreenSlots {
  default(props: Dictionary<never>): void;
}

const props = defineProps<SportlineDropdownMenuFullscreenProps>();
const emit = defineEmits<SportlineDropdownMenuFullscreenEmits>();
defineSlots<SportlineDropdownMenuFullscreenSlots>();

const isOpen = toRef(props, 'isOpen');

function emitClose(): void {
  emit('update:isOpen', false);
}

const { menu } = useCloseOnClick({ isOpen, emitClose });
</script>

<template>
  <div v-auto-id="'SportlineDropdownMenuFullscreen'"
    v-if="isOpen"
    ref="menu"
    :class="$style['sportline-dropdown-menu']"
  >
    <slot />

    <div
      :class="$style['sportline-dropdown-menu__mask']"
      @click.prevent="emitClose"
    />
  </div>
</template>

<style module lang="scss">
.sportline-dropdown-menu {
  position: absolute;
  right: 0;
  z-index: z-index(dropdown-menu);
  width: 100%;
  height: 100%;

  &__mask {
    width: 100%;
    height: 100%;
    background: var(--OpacityLayer0);
  }
}
</style>
